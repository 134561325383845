<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <!-- <MyTreeSelect :value="departmentName"
                          placeholder="所属机构"
                          clearable
                          :data="departmentTreeArr"
                          search
                          class="m-r-10"
                          style="width:160px"
                          @onSelectChange="onChangeDepartment">
            </MyTreeSelect> -->
            <!-- <MyTreeSelect :value="carNumber"
                          placeholder="车牌号"
                          :data="carTreeArr"
                          search
                          clearable
                          class="m-r-10"
                          style="width:160px"
                          @onSelectChange="onChangeCarName">
            </MyTreeSelect> -->
            <MyTreeSelect :value="departmentName"
                          placeholder="所属机构"
                          clearable
                          :data="departmentTreeArr"
                          class="m-r-10"
                          style="width:160px"
                          @onSelectChange="onChangeDepartment">
            </MyTreeSelect>
            <Select v-model="remoteCar"
                    filterable
                    clearable
                    placeholder="请输入车牌号"
                    :remote-method="remoteMethod"
                    :loading="remoteCarLoading"
                    class="m-r-10"
                    style="width:160px"
                    @on-query-change="onQueryChangeRemoteCar">
              <Option v-for="(option, index) in remoteCarList"
                      :value="option.id"
                      :key="option.id">{{option.name}}</Option>
            </Select>
            <template v-if="currentTabIndex===0">
              <Input v-model="oilCardNumber"
                     placeholder="油卡编号"
                     class="m-r-10"
                     style="width:160px"
                     maxlength="30"
                     v-intLimit />
              <!-- <Input v-model="facilitator"
                     placeholder="服务商"
                     class="m-r-10"
                     style="width:160px" /> -->
            </template>
            <template v-if="currentTabIndex===1">
              <Input v-model="repairOrder"
                     placeholder="维修单号"
                     class="m-r-10"
                     style="width:160px"
                     maxlength="30"
                     v-intLimit />
              <!-- <Input v-model="facilitator"
                     placeholder="服务商"
                     class="m-r-10"
                     style="width:160px" /> -->
            </template>
            <template v-if="currentTabIndex===2">
              <Input v-model="maintainOrder"
                     placeholder="保养单号"
                     class="m-r-10"
                     style="width:160px"
                     maxlength="30"
                     v-intLimit />
              <!-- <Input v-model="facilitator"
                     placeholder="服务商"
                     class="m-r-10"
                     style="width:160px" /> -->
            </template>
            <template v-if="currentTabIndex===3">
              <Input v-model="examineOrder"
                     placeholder="年检单号"
                     class="m-r-10"
                     style="width:160px"
                     maxlength="30"
                     v-intLimit />
              <!-- <Input v-model="facilitator"
                     placeholder="服务商"
                     class="m-r-10"
                     style="width:160px" /> -->
            </template>
            <template v-if="currentTabIndex===4">
              <Input v-model="insuranceOrder"
                     placeholder="保险单号"
                     class="m-r-10"
                     style="width:160px"
                     maxlength="30"
                     v-intLimit />
              <!-- <Input v-model="facilitator"
                     placeholder="服务商"
                     class="m-r-10"
                     style="width:160px" /> -->
            </template>
            <template v-if="currentTabIndex===5">
              <Input v-model="violationOrder"
                     placeholder="违章单号"
                     class="m-r-10"
                     style="width:160px"
                     maxlength="30"
                     v-intLimit />
            </template>
            <template v-if="currentTabIndex===6">
              <Select v-model="costType"
                      placeholder="费用类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in costTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.costname }}</Option>
              </Select>
            </template>
            <DatePicker :value="date"
                        type="daterange"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        class="m-r-10"
                        style="width:260px"></DatePicker>
            <template v-if="currentTabIndex===0">
              <!-- <Checkbox v-model="standbyCard">备用卡加油</Checkbox> -->
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/carCost/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/carCost/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex">
        <Button type="primary"
                class="m-r-10"
                v-if="checkPermi(['/admin/carCost/add'])"
                @click="onClickAddModal">
          <Icon custom="i-icon icon-tianjia1"
                size="16"></Icon>
          添加
        </Button>
        <Dropdown v-if="checkPermi(['/admin/carCost/export'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="part">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('part')">
                <Button>
                  导出部分
                </Button>
              </ExportTemplate>
            </DropdownItem>
            <DropdownItem name="now">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('now')">
                <Button>
                  导出本页
                </Button>
              </ExportTemplate>
            </DropdownItem>
            <DropdownItem name="all">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('all')">
                <Button>
                  导出全部
                </Button>
              </ExportTemplate>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <ExportTemplate :path="getExportTemplatePath"
                        v-if="checkPermi(['/admin/carCost/export'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出模板
          </Button>
        </ExportTemplate>
        <ReaderFile @uploadSuccess="onClickReader"
                    accept=".xls,.xlsx"
                    :action='getReaderFileAction'
                    v-if="checkPermi(['/admin/carCost/import'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiazai"
                  size="16"></Icon>
            导入
          </Button>
        </ReaderFile>
        <Dropdown @on-click="onClickBatch"
                  v-if="checkPermi(['/admin/carCost/delete'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiugai1"
                  size="16"></Icon>
            批量操作
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="delete"
                          v-if="checkPermi(['/admin/carCost/delete'])">批量删除</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               :row-class-name="rowClassName"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <template v-if="currentTabIndex===0">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/edit']"
                      @click.stop="onClickEditOil(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/delete']"
                      @click.stop="onClickDeleteOil(row)">删除</Button>
            </template>
            <template v-if="currentTabIndex===1">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/edit']"
                      @click.stop="onClickEditRepair(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/delete']"
                      @click.stop="onClickDeleteRepair(row)">删除</Button>
            </template>
            <template v-if="currentTabIndex===2">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/edit']"
                      @click.stop="onClickEditMaintain(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/delete']"
                      @click.stop="onClickDeleteMaintain(row)">删除</Button>
            </template>
            <template v-if="currentTabIndex===3">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/edit']"
                      @click.stop="onClickEditExamine(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/delete']"
                      @click.stop="onClickDeleteExamine(row)">删除</Button>
            </template>
            <template v-if="currentTabIndex===4">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/edit']"
                      @click.stop="onClickEditInsurance(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/delete']"
                      @click.stop="onClickDeleteInsurance(row)">删除</Button>
            </template>
            <template v-if="currentTabIndex===5">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/edit']"
                      @click.stop="onClickEditViolation(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/delete']"
                      @click.stop="onClickDeleteViolation(row)">删除</Button>
            </template>
            <template v-if="currentTabIndex===6">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/edit']"
                      @click.stop="onClickEditOther(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/carCost/delete']"
                      @click.stop="onClickDetailOther(row)">删除</Button>
            </template>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>

    <!-- 油费 -->
    <CarCostOilModal v-model="carCostOilVisible"
                     :dataId.sync="carCostOilId"
                     @onClickConfirm="getList"
                     @onChange='onChangClearRowStyle'></CarCostOilModal>

    <!-- 维修费 -->
    <CarCostRepairModal v-model="carCostRepairVisible"
                        :dataId.sync="carCostRepairId"
                        @onClickConfirm="getList"
                        @onChange='onChangClearRowStyle'></CarCostRepairModal>

    <!-- 保养费 -->
    <CarCostMaintainModal v-model="carCostMaintainVisible"
                          :dataId.sync="carCostMaintainId"
                          @onClickConfirm="getList"
                          @onChange='onChangClearRowStyle'></CarCostMaintainModal>

    <!-- 年检费 -->
    <CarCostExamineModal v-model="carCostExamineVisible"
                         :dataId.sync="carCostExamineId"
                         @onClickConfirm="getList"
                         @onChange='onChangClearRowStyle'></CarCostExamineModal>

    <!-- 保险费 -->
    <CarCostInsuranceModal v-model="carCostInsuranceVisible"
                           :dataId.sync="carCostInsuranceId"
                           @onClickConfirm="getList"
                           @onChange='onChangClearRowStyle'></CarCostInsuranceModal>

    <!-- 违章费 -->
    <CarCostViolationModal v-model="carCostViolationVisible"
                           :dataId.sync="carCostViolationId"
                           @onClickConfirm="getList"
                           @onChange='onChangClearRowStyle'></CarCostViolationModal>

    <!-- 车杂费 -->
    <CarCostOtherModal v-model="carCostOtherVisible"
                       :dataId.sync="carCostOtherId"
                       @onClickConfirm="getList"
                       @onChange='onChangClearRowStyle'></CarCostOtherModal>
  </div>
</template>

<script>
import CarCostOilModal from '@/components/product/admin/modal/cost/CarCostOilModal'
import CarCostRepairModal from '@/components/product/admin/modal/cost/CarCostRepairModal'
import CarCostMaintainModal from '@/components/product/admin/modal/cost/CarCostMaintainModal'
import CarCostExamineModal from '@/components/product/admin/modal/cost/CarCostExamineModal'
import CarCostInsuranceModal from '@/components/product/admin/modal/cost/CarCostInsuranceModal'
import CarCostViolationModal from '@/components/product/admin/modal/cost/CarCostViolationModal'
import CarCostOtherModal from '@/components/product/admin/modal/cost/CarCostOtherModal'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { mapActions, mapGetters } from 'vuex'
import ReaderFile from '@/components/common/ReaderFile'
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
import { download } from '@/utils/utils'
export default {
  components: {
    CarCostOilModal,
    CarCostRepairModal,
    CarCostMaintainModal,
    CarCostExamineModal,
    CarCostInsuranceModal,
    CarCostViolationModal,
    CarCostOtherModal,
    MyTreeSelect,
    ExportTemplate,
    ReaderFile
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '油费'
        },
        {
          name: '维修费'
        },
        {
          name: '保养费'
        },
        {
          name: '年检费'
        },
        {
          name: '保险费'
        },
        {
          name: '违章费'
        },
        {
          name: '车杂费'
        },
      ],
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      departmentName: '',
      departmentId: '',
      carNumber: '',
      carNumberId: '',
      oilCardNumber: '',
      repairOrder: '',
      maintainOrder: '',
      examineOrder: '',
      insuranceOrder: '',
      violationOrder: '',
      facilitator: '',
      standbyCard: false,
      costType: -1,
      costTypeArr: [],
      date: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columnsOil: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carCardNum',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '驾驶员',
          key: 'driverName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '油卡编号',
          key: 'oilcardnum',
          tooltip: true,
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'totalcost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '加油时间',
          key: 'costtimeString',
          tooltip: true,
          align: 'center'
        },
        {
          title: '加油量(L)',
          key: 'oilnum',
          tooltip: true,
          align: 'center'
        },
        {
          title: '燃油类型',
          key: 'repairreason',
          tooltip: true,
          align: 'center'
        },
        {
          title: '单价(元/L)',
          key: 'oilunitprice',

          tooltip: true,
          align: 'center'
        },
        {
          title: '码表里程(km)',
          key: 'clockmileage',
          width: 100,
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '服务商',
        //   key: 'servicevendorname',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '地址',
          key: 'place',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'payusername',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'atta',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '车辆类型',
          key: 'carTyeName',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '燃油类型',
        //   key: 'oiltypename',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      columnsRepair: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carCardNum',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '驾驶员',
          key: 'driverName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '码表里程(km)',
          key: 'clockmileage',
          width: 100,
          tooltip: true,
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'totalcost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '维修部位',
          key: 'repairreasonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '维修日期',
          key: 'costtime',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '服务商',
        //   key: 'servicevendorname',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '地址',
          key: 'place',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'payusername',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'atta',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '维修单号',
          key: 'orderno',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTyeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      columnsMaintain: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carCardNum',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '驾驶员',
          key: 'driverName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '码表里程(km)',
          key: 'clockmileage',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'totalcost',
          tooltip: true,
          width: 70,
          align: 'center'
        },
        {
          title: '保养部位',
          key: 'repairreasonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '保养日期',
          key: 'costtime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '保养里程间隔',
          key: 'maintainmileageintervalname',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '保养时间间隔',
          key: 'maintaintimeintervalname',
          width: 100,
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '服务商',
        //   key: 'servicevendorname',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '地址',
          key: 'place',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'payusername',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'atta',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '保养单号',
          key: 'orderno',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTyeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 90,
          align: 'center'
        }
      ],
      columnsExamine: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carCardNum',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '码表里程(km)',
          key: 'clockmileage',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'totalcost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '年检项目',
          key: 'repairreasonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '年检日期',
          key: 'costtime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'payusername',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'atta',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '年检单号',
          key: 'orderNo',
          tooltip: true,
          align: 'center'
        },
        {
          title: '地址',
          key: 'place',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTyeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      columnsInsurance: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carCardNum',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '码表里程(km)',
          key: 'clockmileage',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'totalcost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '保险类型',
          key: 'repairreasonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '购保日期',
          key: 'costtime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '保险开始日期',
          key: 'insurancestarttime',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        // {
        //   title: '服务商',
        //   key: 'servicevendorname',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '地址',
          key: 'place',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'payusername',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'managePhone',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '保险单号',
          key: 'orderno',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTyeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      columnsViolation: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carCardNum',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '驾驶员',
          key: 'driverName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '码表里程(km)',
          key: 'clockmileage',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '违章费用(元)',
          key: 'totalcost',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '违章类型',
          key: 'repairreasonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违章时间',
          key: 'costtime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'payusername',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违章地点',
          key: 'place',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'atta',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '违章单号',
          key: 'orderno',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTyeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      columnsOther: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carCardNum',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '驾驶员',
          key: 'driverName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '码表里程(km)',
          key: 'clockmileage',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '费用(元)',
          key: 'totalcost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '费用类型',
          key: 'repairreasonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费时间',
          key: 'costtime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费人',
          key: 'payusername',
          tooltip: true,
          align: 'center'
        },
        {
          title: '地址',
          key: 'place',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'atta',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '车辆类型',
          key: 'carTyeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 油费
      carCostOilVisible: false,
      carCostOilId: '',
      // 维修费
      carCostRepairVisible: false,
      carCostRepairId: '',
      // 保养费
      carCostMaintainVisible: false,
      carCostMaintainId: '',
      // 年检费
      carCostExamineVisible: false,
      carCostExamineId: '',
      // 保险费
      carCostInsuranceVisible: false,
      carCostInsuranceId: '',
      // 违章费
      carCostViolationVisible: false,
      carCostViolationId: '',
      // 车杂费
      carCostOtherVisible: false,
      carCostOtherId: '',
    };
  },
  watch: {
    currentTabIndex (newVal) {
      this.resetSearchData()
      this.onClickSearch()
      if (newVal == 6) {
        this.getDoctSmallTypeItem()
      }
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'carTreeArr': 'getCarTreeList',
      'departmentTreeArr': 'getDepartmentTreeList',
      'dictBigTypeList': 'getDictBigTypeList'
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsOil
          break;
        case 1:
          columns = this.columnsRepair
          break;
        case 2:
          columns = this.columnsMaintain
          break;
        case 3:
          columns = this.columnsExamine
          break;
        case 4:
          columns = this.columnsInsurance
          break;
        case 5:
          columns = this.columnsViolation
          break;
        case 6:
          columns = this.columnsOther
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportCarCostOil
          break;
        case 1:
          path = this.$http.exportCarCostRepair
          break;
        case 2:
          path = this.$http.exportCarCostMaintain
          break;
        case 3:
          path = this.$http.exportCarCostExamine
          break;
        case 4:
          path = this.$http.exportCarCostInsurance
          break;
        case 5:
          path = this.$http.exportCarCostViolation
          break;
        case 6:
          path = this.$http.exportCarCostOther
          break;
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportCarCostOilTem
          break;
        case 1:
          path = this.$http.exportCarCostRepairTem
          break;
        case 2:
          path = this.$http.exportCarCostMaintainTem
          break;
        case 3:
          path = this.$http.exportCarCostExamineTem
          break;
        case 4:
          path = this.$http.exportCarCostInsuranceTem
          break;
        case 5:
          path = this.$http.exportCarCostViolationTem
          break;
        case 6:
          path = this.$http.exportCarCostOtherTem
          break;
        default:
          break;
      }
      return path
    },
    getReaderFileAction () {
      let readerFileAction = []
      switch (this.currentTabIndex) {
        case 0:
          readerFileAction = '/hss/tloilcostcfg/excelReader'
          break;
        case 1:
          readerFileAction = '/hss/tlrepaircfg/excelReader'
          break;
        case 2:
          readerFileAction = '/hss/tlmaintaincfg/excelReader'
          break;
        case 3:
          readerFileAction = '/hss/tlinspectioncfg/excelReader'
          break;
        case 4:
          readerFileAction = '/hss/tlinsurancecfg/excelReader'
          break;
        case 5:
          readerFileAction = '/hss/tlviolationcfg/excelReader'
          break;
        case 6:
          readerFileAction = '/hss/tlothercarcostcfg/excelReader'
          break;
        default:
          break;
      }
      return readerFileAction
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updateCarTreeList',
      'updateDictBigTypeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateCarTreeList()
      this.updateDepartmentTreeList()
      this.updateDictBigTypeList()
      this.getList()
    },
    getDoctSmallTypeItem () {
      let index = this.dictBigTypeList.findIndex(item => {
        return item.costtype == '车杂费'
      })
      this.$http.getCostDictionariesItemAllList({ id: this.dictBigTypeList[index].id }).then((res) => {
        if (res.code === 200) {
          this.costTypeArr = res.result
        }
      })
    },
    // 判断文件类型
    getFileType (file) {
      let filePath = file
      let index = filePath.lastIndexOf(".");
      let type = filePath.substr(index + 1);
      let imgArr = ['tif', 'pjp', 'webp', 'xbm', 'jxl', 'svgz', 'jpg', 'jpeg', 'ico', 'tiff', 'gif', 'svg', 'bmp', 'png', 'jfif', 'pjpeg', 'avif']
      if (imgArr.includes(type)) {
        return 1
      } else {
        return 2
      }
    },
    // 点击下载文件
    onClickDownFile (row) {
      window.open(row.atta)
    },
    // 修改车牌号
    onChangeCarName (section) {
      if (section.type === 'car') {
        this.carNumberId = section.id
        this.carNumber = section.name
      }
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    onClickSet () {

    },
    onChangeDate (date) {
      this.date = date
    },
    onClickBatch (name) {
      switch (name) {
        case 'delete':
          switch (this.currentTabIndex) {
            case 0:
              this.onDeleteOil(this.selectArr)
              break;
            case 1:
              this.onDeleteRepair(this.selectArr)
              break;
            case 2:
              this.onDeleteMaintain(this.selectArr)
              break;
            case 3:
              this.onDeleteExamine(this.selectArr)
              break;
            case 4:
              this.onDeleteInsurance(this.selectArr)
              break;
            case 5:
              this.onDeleteViolation(this.selectArr)
              break;
            case 6:
              this.onDetailOther(this.selectArr)
              break;

            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    // 多选
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
      // console.log(this.selectArr);
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 添加按钮
    onClickAddModal () {
      switch (this.currentTabIndex) {
        case 0:
          this.carCostOilVisible = true
          break;
        case 1:
          this.carCostRepairVisible = true
          break;
        case 2:
          this.carCostMaintainVisible = true
          break;
        case 3:
          this.carCostExamineVisible = true
          break;
        case 4:
          this.carCostInsuranceVisible = true
          break;
        case 5:
          this.carCostViolationVisible = true
          break;
        case 6:
          this.carCostOtherVisible = true
          break;
        default:
          break;
      }
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getCarCostOilList()
          break;
        case 1:
          this.getCarCostRepairList()
          break;
        case 2:
          this.getCarCostMaintainList()
          break;
        case 3:
          this.getCarCostExamineList()
          break;
        case 4:
          this.getCarCostInsuranceList()
          break;
        case 5:
          this.getCarCostViolationList()
          break;
        case 6:
          this.getCarCostOtherList()
          break;
        default:
          break;
      }
    },
    // 油费
    getCarCostOilList () {
      let params = {
        deptidforcar: this.departmentId,
        carNumid: this.remoteCar,
        serviceVendorName: this.facilitator,
        oilCardNum: this.oilCardNumber,
        page: this.pageNum,
        size: this.pageSize
      }
      if (this.date[0]) {
        params.startTime = this.date[0]
        params.endTime = this.date[1]
      }
      this.tableLoading = true
      this.$http.getCarCostOilList(params).then((res) => {
        this.data = res.result.oillist
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    onClickEditOil (row) {
      this.selectVisitedArr.push(row)
      this.carCostOilVisible = true
      this.carCostOilId = row.id
    },
    onClickDeleteOil (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteOil([row.id])
    },
    // 批量删除
    onDeleteOil (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deleteCarCostOil(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 维修
    getCarCostRepairList () {
      let params = {
        deptIdForCar: this.departmentId,
        carNumid: this.carNumberId,
        serviceVendorName: this.facilitator,
        orderNo: this.repairOrder,
        page: this.pageNum,
        size: this.pageSize
      }
      if (this.date[0]) {
        params.startTime = this.date[0]
        params.endTime = this.date[1]
      }
      this.tableLoading = true
      this.$http.getCarCostRepairList(params).then((res) => {
        this.data = res.result.reapairlist
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    onClickEditRepair (row) {
      this.selectVisitedArr.push(row)
      this.carCostRepairVisible = true
      this.carCostRepairId = row.id
    },
    onClickDeleteRepair (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteRepair([row.id])
    },
    onDeleteRepair (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deleteCarCostRepair(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 保养
    getCarCostMaintainList () {
      let params = {
        deptIdForCar: this.departmentId,
        carNumid: this.carNumberId,
        serviceVendorName: this.facilitator,
        orderNo: this.maintainOrder,
        page: this.pageNum,
        size: this.pageSize
      }
      if (this.date[0]) {
        params.startTime = this.date[0]
        params.endTime = this.date[1]
      }
      this.tableLoading = true
      this.$http.getCarCostMaintainList(params).then((res) => {
        this.data = res.result.maintainlist
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改保养
    onClickEditMaintain (row) {
      this.selectVisitedArr.push(row)
      this.carCostMaintainVisible = true
      this.carCostMaintainId = row.id
    },
    // 删除保养
    onClickDeleteMaintain (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteMaintain([row.id])
    },
    onDeleteMaintain (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deleteCarCostMaintain(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 年检
    getCarCostExamineList () {
      let params = {
        deptid: this.departmentId,
        carNumid: this.carNumberId,
        orderNo: this.examineOrder,
        page: this.pageNum,
        size: this.pageSize
      }
      if (this.date[0]) {
        params.startTime = this.date[0]
        params.endTime = this.date[1]
      }
      this.tableLoading = true
      this.$http.getCarCostExamineList(params).then((res) => {
        this.data = res.result.rows
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改年检费
    onClickEditExamine (row) {
      this.selectVisitedArr.push(row)
      this.carCostExamineVisible = true
      this.carCostExamineId = row.id
    },
    // 删除年检费
    onClickDeleteExamine (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteExamine([row.id])
    },
    onDeleteExamine (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deleteCarCostExamine(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 保险
    getCarCostInsuranceList () {
      let params = {
        deptIdForCar: this.departmentId,
        carNumid: this.carNumberId,
        orderNo: this.insuranceOrder,
        serviceVendorName: this.facilitator,
        page: this.pageNum,
        size: this.pageSize
      }
      if (this.date[0]) {
        params.startTime = this.date[0]
        params.endTime = this.date[1]
      }
      this.tableLoading = true
      this.$http.getCarCostInsuranceList(params).then((res) => {
        this.data = res.result.insurancelist
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改保险费
    onClickEditInsurance (row) {
      this.selectVisitedArr.push(row)
      this.carCostInsuranceVisible = true
      this.carCostInsuranceId = row.id
    },
    // 删除保险费
    onClickDeleteInsurance (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteInsurance([row.id])
    },
    // 删除保险费
    onDeleteInsurance (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deleteCarCostInsurance(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 违章
    getCarCostViolationList () {
      let params = {
        deptIdForCar: this.departmentId,
        carNumid: this.carNumberId,
        orderNo: this.violationOrder,
        page: this.pageNum,
        size: this.pageSize
      }
      if (this.date[0]) {
        params.startTime = this.date[0]
        params.endTime = this.date[1]
      }
      this.tableLoading = true
      this.$http.getCarCostViolationList(params).then((res) => {
        this.data = res.result.violationlist
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改违章费
    onClickEditViolation (row) {
      this.selectVisitedArr.push(row)
      this.carCostViolationVisible = true
      this.carCostViolationId = row.id
    },
    // 删除违章费
    onClickDeleteViolation (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteViolation([row.id])
    },
    // 删除违章费
    onDeleteViolation (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deleteCarCostViolation(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 车杂
    getCarCostOtherList () {
      let params = {
        deptIdForCar: this.departmentId,
        carNumid: this.carNumberId,
        costType: this.costType,
        page: this.pageNum,
        size: this.pageSize
      }
      if (this.date[0]) {
        params.startTime = this.date[0]
        params.endTime = this.date[1]
      }
      this.tableLoading = true
      this.$http.getCarCostOtherList(params).then((res) => {
        this.data = res.result.othercarcostlist
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改车杂费
    onClickEditOther (row) {
      this.selectVisitedArr.push(row)
      this.carCostOtherVisible = true
      this.carCostOtherId = row.id
    },
    // 删除车杂费
    onClickDetailOther (row) {
      this.selectVisitedArr.push(row)
      this.onDetailOther([row.id])
    },
    // 删除车杂费
    onDetailOther (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deleteCarCostOther(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = []
          }
        })
      } else {
        this.remoteCarList = []
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      // 油费
      this.oilCardNumber = ''
      this.repairOrder = ''
      this.maintainOrder = ''
      this.examineOrder = ''
      this.insuranceOrder = ''
      this.violationOrder = ''
      this.costType = ''
      this.remoteCar = ''
      this.departmentName = ''
      this.departmentId = ''
      this.carNumberId = ''
      this.carNumber = ''
      this.facilitator = ''

      this.date = []
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            carNumid: this.carNumberId,
            deptid: this.departmentId,
            oilCardNum: this.oilCardNumber,
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        case 1:
          data = {
            carNumid: this.carNumberId,
            deptid: this.departmentId,
            repairOddNumbers: this.repairOrder,
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        case 2:
          data = {
            carNumid: this.carNumberId,
            deptid: this.departmentId,
            maintainOddNumbers: this.maintainOrder,
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        case 3:
          data = {
            carNumid: this.carNumberId,
            deptid: this.departmentId,
            orderNo: this.examineOrder,
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        case 4:
          data = {
            carNumid: this.carNumberId,
            deptid: this.departmentId,
            policyNo: this.insuranceOrder,
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        case 5:
          data = {
            carNumid: this.carNumberId,
            deptid: this.departmentId,
            orderNo: this.violationOrder,
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        case 6:
          data = {
            carNumid: this.carNumberId,
            deptid: this.departmentId,
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>